var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c(
        "b-row",
        { staticClass: "pb-50" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-between flex-column",
              attrs: { lg: "6", "order-lg": "1", order: "2" }
            },
            [
              _c(
                "div",
                { staticClass: "mt-1 mt-lg-0" },
                [
                  _c("h2", { staticClass: "font-weight-bolder mb-25" }, [
                    _vm._v(
                      " " + _vm._s(_vm.kFormatter(_vm.avgData.sessions)) + " "
                    )
                  ]),
                  _c("b-card-text", { staticClass: "font-weight-bold mb-2" }, [
                    _vm._v(" Avg Sessions ")
                  ]),
                  _c("div", { staticClass: "font-medium-2 mb-1 mb-lg-0" }, [
                    _c("span", { staticClass: "text-success mr-50" }, [
                      _vm._v(_vm._s(_vm.avgData.growth))
                    ]),
                    _c("span", [_vm._v("vs last 7 days")])
                  ])
                ],
                1
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(255, 255, 255, 0.15)",
                      expression: "'rgba(255, 255, 255, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  staticClass: "shadow",
                  attrs: { variant: "primary" }
                },
                [_vm._v(" View Details ")]
              )
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass:
                "d-flex justify-content-between flex-column text-right",
              attrs: { lg: "6", cols: "12", "order-lg": "2", order: "1" }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "chart-dropdown",
                      attrs: {
                        text: "Last 7 Days",
                        variant: "transparent",
                        left: "",
                        "no-caret": "",
                        size: "sm"
                      }
                    },
                    _vm._l(_vm.avgData.lastDays, function(day) {
                      return _c("b-dropdown-item", { key: day }, [
                        _vm._v(" " + _vm._s(day) + " ")
                      ])
                    }),
                    1
                  )
                ],
                1
              ),
              _c("vue-apex-charts", {
                attrs: {
                  type: "bar",
                  height: "200",
                  options: _vm.salesBar.chartOptions,
                  series: _vm.salesBar.series
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("hr"),
      _c(
        "b-row",
        { staticClass: "avg-sessions pt-50" },
        [
          _c(
            "b-col",
            { staticClass: "mb-2", attrs: { cols: "6" } },
            [
              _c("b-card-text", { staticClass: "mb-50" }, [
                _vm._v(" Goal: $" + _vm._s(_vm.avgData.goal) + " ")
              ]),
              _c("b-progress", {
                attrs: { value: "50", max: "100", height: "6px" }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-2", attrs: { cols: "6" } },
            [
              _c("b-card-text", { staticClass: "mb-50" }, [
                _vm._v(
                  " Users: " + _vm._s(_vm.kFormatter(_vm.avgData.users)) + " "
                )
              ]),
              _c("b-progress", {
                attrs: {
                  value: "60",
                  max: "100",
                  height: "6px",
                  variant: "warning"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("b-card-text", { staticClass: "mb-50" }, [
                _vm._v(" Retention: " + _vm._s(_vm.avgData.retention) + "% ")
              ]),
              _c("b-progress", {
                staticClass: "mt-25",
                attrs: {
                  value: "70",
                  max: "100",
                  height: "6px",
                  variant: "danger"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("b-card-text", { staticClass: "mb-50" }, [
                _vm._v(" Duration: " + _vm._s(_vm.avgData.duration) + "yr ")
              ]),
              _c("b-progress", {
                staticClass: "mt-25",
                attrs: {
                  value: "90",
                  max: "100",
                  variant: "success",
                  height: "6px"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }