var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        { staticClass: "pb-0" },
        [
          _c("b-card-title", [_vm._v(_vm._s(_vm.trackerData.title))]),
          _c(
            "b-dropdown",
            {
              staticClass: "chart-dropdown",
              attrs: {
                "no-caret": "",
                right: "",
                text: "Last 7 Days",
                variant: "transparent",
                "toggle-class": "p-50",
                size: "sm"
              }
            },
            _vm._l(_vm.trackerData.lastDays, function(day) {
              return _c("b-dropdown-item", { key: day }, [
                _vm._v(" " + _vm._s(day) + " ")
              ])
            }),
            1
          )
        ],
        1
      ),
      _c(
        "b-card-body",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex flex-column flex-wrap text-center",
                  attrs: { sm: "2" }
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "font-large-2 font-weight-bolder mt-2 mb-0"
                    },
                    [_vm._v(" " + _vm._s(_vm.trackerData.totalTicket) + " ")]
                  ),
                  _c("b-card-text", [_vm._v("Tickets")])
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-center",
                  attrs: { sm: "10" }
                },
                [
                  _c("vue-apex-charts", {
                    attrs: {
                      type: "radialBar",
                      height: "270",
                      options: _vm.supportTrackerRadialBar.chartOptions,
                      series: _vm.supportTrackerRadialBar.series
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("b-card-text", { staticClass: "mb-50" }, [
                  _vm._v(" New Tickets ")
                ]),
                _c("span", { staticClass: "font-large-1 font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.trackerData.newTicket))
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("b-card-text", { staticClass: "mb-50" }, [
                  _vm._v(" Open Tickets ")
                ]),
                _c("span", { staticClass: "font-large-1 font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.trackerData.openTicket))
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("b-card-text", { staticClass: "mb-50" }, [
                  _vm._v(" Response Time ")
                ]),
                _c("span", { staticClass: "font-large-1 font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.trackerData.responseTime) + "d")
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }