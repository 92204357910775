var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        { staticClass: "align-items-baseline pb-1" },
        [
          _c(
            "div",
            [
              _c("b-card-title", { staticClass: "mb-25" }, [_vm._v(" Sales ")]),
              _c("b-card-text", { staticClass: "mb-0" }, [
                _vm._v(" Last 6 months ")
              ])
            ],
            1
          ),
          _c("feather-icon", {
            staticClass: "cursor-pointer",
            attrs: { icon: "MoreVerticalIcon", size: "18" }
          })
        ],
        1
      ),
      _c(
        "b-card-body",
        [
          _c(
            "div",
            { staticClass: "d-inline-flex align-items-center mr-2" },
            [
              _c("feather-icon", {
                staticClass: "text-primary mr-50",
                attrs: { icon: "CircleIcon" }
              }),
              _c("h6", { staticClass: "mb-0" }, [_vm._v(" Sales ")])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-inline-flex align-items-center" },
            [
              _c("feather-icon", {
                staticClass: "text-info mr-50",
                attrs: { icon: "CircleIcon" }
              }),
              _c("h6", { staticClass: "mb-0" }, [_vm._v(" Visits ")])
            ],
            1
          ),
          _c("vue-apex-charts", {
            attrs: {
              type: "radar",
              height: "300",
              options: _vm.statisticsRadar.chartOptions,
              series: _vm.statisticsRadar.series
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }